import "./App.css";
import Footer from "./Components/Footer/Footer";
import Made from "./Components/Made/Made";
import Main from "./Components/Main/Main";
import Mint from "./Components/Mint/Mint";
import { useEffect } from "react";
import {
  publicMint,
  purchaseTokensPlusPurchaseTokensFree,
} from "./eth-setup/apis";

function App() {
  useEffect(() => {
    0 && publicMint(() => {}, 1);
    0 && purchaseTokensPlusPurchaseTokensFree();
  }, []);

  return (
    // <div>
    //   <h1
    //     style={{
    //       textAlign: "center",
    //       marginTop: "100px",
    //       fontFamily: "Comic Neue",
    //       fontWeight: "600",
    //     }}
    //   >
    //     COMING SOON
    //   </h1>{" "}
    // </div>

    <div className="App">
      {/* <Navbar /> */}
      <Main />
      {/* <Header /> */}
      <Mint />
      {/* <Rules /> */}
      <Made />
      {/* <Whitelist /> */}
      {/* <Animation /> */}
      {/* <Collection /> */}
      <div className="dark-background">
        {/* <Team /> */}
        {/* <Safety /> */}
        {/* <Faq /> */}
        <Footer />
      </div>
    </div>
  );
}

export default App;
