import React from "react";
import img1 from "../Image/Header1.png";
import img2 from "../Image/Header2.gif";
import "./Main.css";
const Main = () => {
  return (
    <div className="main">
      <a name="about"></a>
      <div className="container">
        <div className="col-10 mx-auto px-3">
          {/* <div className="icon-part d-flex justify-content-center mt-5">
            <div className="twitter mx-3">
              <a href="https://twitter.com/FoxNationDAO" target="_blank">
                <BsTwitter
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
            <div className="discord mx-3">
              <a href="https://discord.com/invite/foxnationdao" target="_blank">
                <FaDiscord
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
            <div className="medium mx-3">
              <a href="https://medium.com/@FoxNationDAO" target="_blank">
                <BsMedium
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
          </div> */}

          <div className="main-img-center text-center">
            {/* <a href="https://medium.com/@FoxNationDAO" target="_blank">
            <img
              src={img1}
              alt=""
              className="img-center mb-5 text-center"
              data-aos="fade-up"
            />
          </a> */}

            {/* <div className="main-text text-center mb-3">
              <div className="main-text-title mb-4" data-aos="fade-up"> */}
            <div className="title text-center mt-5">
              <div className="title">MOUS IN DA RIGHT HOUS</div>
              <div className="description mt-3">
                Mice on eth because solana is not da right hous! 🧀 <br />
                20% of the total proceeds will be donated via{" "}
                <a href="https://thegivingblock.com/donate/" target="blank">
                  thegivingblock.com
                </a>
                <br />
                to the Animals Impact Index Fund after the sale of the last
                Mous.
                {/* Mouses on ETH because we want the real cheese 🧀 */}
                {/* Check our{" "}
                <a
                  className="main-link"
                  href="https://medium.com/@LoserApeClub"
                  target="_blank"
                >
                  Medium{" "}
                </a>
                for detailed info. */}
              </div>
            </div>
            <a href="https://discord.gg/aetBpbpayx" target="_blank">
              {/* <video
                autoplay
                muted
                loop
                id="bgvid"
                height="240px"
                width="320px"
                className="img-center text-center img-fluid"
              >
                <source src={video1} type="video/mp4" />
              </video> */}
              <img
                src={img1}
                alt=""
                className="img-center1 text-center img-fluid"
              />
              <img
                src={img2}
                alt=""
                className="img-center2 text-center img-fluid"
              />

              {/* <MainSlider /> */}
            </a>

            {/* main button part  */}
            {/* <div className="main-bottom-buttom">
						<div className="left-btn1">
							<button className="left-btn mb-3" data-aos="zoom-in">
								Buy On Opensea
							</button>
						</div>
					</div> */}
            {/* <span className="bottom-text mt-5 mb-5" data-aos="zoom-in">
              Be careful of fake Fox Nation. Use the <br /> button to get the
              right collection.
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
