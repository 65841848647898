import React, { useState } from "react";
import { purchaseTokensPlusPurchaseTokensFree } from "../../eth-setup/apis";
import "./Mint.css";
const Mint = () => {
  const [qty, setQty] = useState(1);

  return (
    <div className="mint-main padding">
      <a name="mint"></a>
      <div className="container">
        {/* <div className="title mt-3">MINT</div> */}
        <div className="right padding col-10 mx-auto">
          {/* <div className="right-title mb-3 text-center">Mint Here</div> */}
          <div className="description mb-3 text-center">
            MINT YOUR MICE HERE 🐭🐭🐭:
          </div>
          <div className="mint text-center">
            {/* <div className="mint-title mb-2">{total}/5000</div> */}
            <div className="mint-input d-flex justify-content-center align-items-center">
              {/* <div
                className="btn-minus mx-3"
                onClick={() => getValue(value - 1)}
              >
                <i className="fas fa-minus"></i>
                <i class="fa-solid fa-circle-caret-down"></i>
              </div> */}

              <div className="mint-input d-flex justify-content-center align-items-center">
                <input
                  className="input cute-class"
                  onChange={(e) => setQty(e.target.value)}
                  onClick={(e) => setQty(e.target.value)}
                  type="number"
                  min={1}
                  max={10}
                  defaultValue={1}
                />
              </div>

              {/* <div
                className="btn-plus mx-3"
                onClick={() => getValue(value + 1)}
              >
                <i className="fas fa-plus"></i>
              </div> */}
            </div>
            <div className="mint-btn mb-3 mt-4">
              <button
                onClick={() =>
                  purchaseTokensPlusPurchaseTokensFree(() => {}, qty)
                }
                type="button"
                className="btn btn-primary mint-btn-submit"
              >
                MINT
              </button>
            </div>
            {/* <div className="mint-btn mb-3 mt-4">
              <button type="button" className="btn btn-primary mint-btn-submit">
                WHITE LIST
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
