import React from "react";
import "./Made.css";
const Made = () => {
  return (
    <div className="made">
      <div className="container">
        <a name="plans"></a>
        <div className="col-10 mx-auto p-3">
          <div className="title padding"></div>
          <div className="description mb-5">
            Made by community for good vibes. <br />
            Purchase at own risk. <br />
            Not affiliated with mous in da hous. <br />
            Smart contract{" "}
            <a
              href="https://etherscan.io/address/0xfad335c3a1f2a0bf37fefa3b4a0f611474d57b5a#code"
              target="blank"
            >
              here.{" "}
            </a>
            {/* Follow us on{" "}
            <a href="https://twitter.com/LoserApeClub" target="_blank">
              Twitter
            </a>{" "}
            and{" "}
            <a href="https://medium.com/@LoserApeClub" target="_blank">
              Medium
            </a>{" "}
            for up-to-date information. <br /> */}
            {/* <br />
            The fund will begin to be filled after 10% of sales, which will be
            held by the team to cover the initial cost of the project. */}
            {/* Rules and minimum requirements: <br /> */}
            {/* <li>1 Fox = 1 Vote</li>
            <br />
            <li>
              all Fox holders can make their preliminary proposals that, after
              being discussed with the community, can become definitive
              proposals respecting some specific criteria
            </li>
            <br />
            <li>
              the final proposal will need different quorums to be executed,
              depending on the percentage of capital required
            </li>
            <br />
            <li>
              delegates/working groups will be able to act autonomously for
              proposals involving investments below a certain percentage of
              capital
            </li> */}
            {/* <li>
              proposals and votes will take place on{" "}
              <a href="https://snapshot.org/#/foxnationdao.eth" target="_blank">
                Snapshot
              </a>
            </li> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Made;
